<template>
  <div class="med-container" v-loading="supervisionSwitchLoading">
    <div class="med-content">
      <div v-if="expandMuneBoolen" class="left-wrapper">
        <div class="name" style="text-align: center">思派互联网医院</div>
        <!-- 侧边栏 -->
        <el-menu
          :default-active="routerIndex"
          class="med-menu"
          background-color="#002140"
          text-color="#fff"
          active-text-color="#fff"
          :collapse="isMenuHide"
          :unique-opened="isSingleShow"
          @select="handleSelect"
        >
          <SidebarMenu :menu-data="menuData" :default-active="routerIndex" />
        </el-menu>
      </div>
      <!-- 右边内容 -->
      <div class="right-wrapper" :class="{ 'no-menu': !expandMuneBoolen }">
        <!-- 顶部栏 -->
        <common-header :user-info="userInfo" v-if="expandMuneBoolen" />
        <!-- <div class="contents" /> -->
        <!-- <el-breadcrumb v-if="breadcrumbData && breadcrumbData.length > 1" -->
        <!-- 面包屑 -->
        <el-breadcrumb v-if="expandMuneBoolen && breadcrumbData && breadcrumbData.length > 0" class="med-breadcrumb">
          <template v-for="(item, index) in breadcrumbData">
            <el-breadcrumb-item v-if="breadcrumbData.length === index + 1" :key="index">
              {{ item.label }}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-else :key="index + 1" :to="{ path: item.path, query: item.query }">
              {{ item.label }}
            </el-breadcrumb-item>
          </template>
        </el-breadcrumb>
        <!-- 右侧主体区域 -->
        <div
          class="content_box"
          :class="{
            'no-padding': getPadding,
            'reset-Padding': restPadding,
            'btn-fix-padding': fixPadding,
            noPaddingBottom: !expandMuneBoolen,
          }"
        >
          <div
            :class="[
              { 'has-breadcrumb': breadcrumbData.length > 0 },
              'right-content',
              { 'no-padding': getPadding },
              { 'reset-no-padding': fixPadding },
            ]"
          >
            <!--主体部分-->
            <keep-alive :include="['MemberList', 'ServiceManager', 'FaceDiagonse']">
              <router-view />
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
    <!-- 2022-07-01 监管二期去除医生药师视频认证 -->
    <!-- <FaceVerify /> -->
  </div>
</template>
<script>
import { Message } from 'element-ui';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { safeGet, safeParse, findTargetByDeepTraverse, delay } from '@/utils';
import { translateRoleId, translateRoleCategory } from '@/utils/enums';
import { userInfoApi, innerUserInfo } from '@/api';
import defaultToAvatar from '@/assets/images/doctor-header.png';
import CommonHeader from './header';
import SidebarMenu from './sidebar-menu';
import supervisionMixins from '@/utils/supervisionMixins';
import FaceVerify from '../faceVerify/index';
// 获取用户信息
async function getUserInfo() {
  const response = await userInfoApi({
    independent: true,
  });
  const userInfo = _.get(response, ['data', 'data']);
  const identity = _.get(userInfo, ['identity']);
  const accountId = _.get(userInfo, ['accountId']);
  const code = _.get(response, ['data', 'code']);
  const result = await getInnerUserInfo({
    accountId,
    identity,
  });
  if (code === '10021') {
    Message.error('登录已失效，请重新登录员工平台账号');
  }
  return { userInfo: safeGet(response, 'data.data', {}), result };
}
// 获取内部用户信息
async function getInnerUserInfo(data) {
  const response = await innerUserInfo(data);
  return safeGet(response, 'data', {});
}
export default {
  mixins: [supervisionMixins],
  components: {
    CommonHeader,
    SidebarMenu,
    FaceVerify,
  },
  data() {
    return {
      isSingleShow: true,
      userInfo: {}, // 用户数据
      menuData: [], // 菜单数据
      menuSourceData: [], // 菜单数据
      routerIndex: '', // 当前菜单
      isMenuHide: false, // 是否折叠菜单
      breadcrumbData: [], // 面包屑数据
      backUrlData: '', // 面包屑返回路径
      menuCode: '', // 缓存菜单id
      menuQuery: '', // 搜索菜单数据
      matchDataArr: [], // 匹配的数据
      routeData: [], // 路由数据
      routePermission: [], // 路由权限数组
      showSide: true,
    };
  },
  computed: {
    ...mapGetters(['permissionCode']),
    ...mapGetters('supervision', ['supervisionSwitch']),
    expandMuneBoolen() {
      return this.$store.state.expandMuneBoolen;
    },
    getPadding() {
      return this.$store.state.noPadding;
    },
    restPadding() {
      return this.$store.state.paddingValue;
    },
    fixPadding() {
      console.log('his.$store.state.fixPadding: ', this.$store.state.fixPadding);
      return this.$store.state.fixPadding;
    },
  },
  watch: {
    $route(val) {
      this.routerIndex = val.path;
      // 更新面包屑
      this.adjustBreadcrumbOrder();
      this.handleMenuSelectInit();
      if (val.meta && val.meta.hideSide) {
        this.showSide = false;
      } else {
        this.showSide = true;
      }
    },
    expandMuneBoolen: {
      handler(val) {
        console.log('expandMuneBoolen>>>>', val);
      },
      immediate: true,
      deep: true,
    },
    supervisionSwitch: {
      handler(val) {
        this.init();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {
    this.getSide();
  },
  methods: {
    ...mapActions({
      getPermissionCode: 'getPermissionCode',
    }),
    // 初始化
    async init() {
      const userInfo = safeParse(localStorage.getItem('userInfo'), {});
      const permissions = await this.getUserAuth();
      await delay(1000);
      this.removeEnterAnimation();
      this.userInfo = userInfo;
      this.routePermission = permissions;
      this.menuData = this.createTreeMenu(1);
      this.menuSourceData = JSON.parse(JSON.stringify(this.menuData));
      this.adjustBreadcrumbOrder();
      this.handleMenuSelectInit();
      // if (
      //     this.checkUserPermission(this.$route)
      //     || this.$route.meta.auth === false
      // ) {
      //     this.menuData = this.createTreeMenu(1);
      //     this.adjustBreadcrumbOrder();
      //     this.handleMenuSelectInit();
      //     this.menuSourceData = JSON.parse(JSON.stringify(this.menuData));
      // } else {
      //     this.$router.push('/forbid');
      // }
    },
    // 移除开场动画
    removeEnterAnimation() {
      const node = document.getElementById('BEFORE_ENTER_ANIMATION');
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
      }
    },
    /**
     * 生成面包屑配置
     */
    createdBreadcrumbSetting() {
      this.breadcrumbData = [];
      const { matchDataArr } = this;
      const { fullPath } = this.$route;
      const fullPathArr = fullPath.split('/');
      const prePath1 = fullPathArr.slice(0, fullPathArr.length - 1).join('/');
      const prePath2 = fullPathArr.slice(0, fullPathArr.length - 2).join('/');
      matchDataArr.forEach((item) => {
        const { path, query, label } = item;
        const queryTemp = {};
        if (prePath2.includes(path) || prePath1.includes(path) || fullPath.includes(path)) {
          if (Array.isArray(query)) {
            query.forEach((field) => {
              queryTemp[field] = this.$route.query[field];
            });
          }
          this.breadcrumbData.push({
            path,
            label,
            query: queryTemp,
          });
        }
      });
    },
    /**
     * 动态调整面包屑的顺序
     */
    adjustBreadcrumbOrder() {
      this.matchDataArr = [];
      this.routeData = this.createTreeMenu(2);
      const { fullPath } = this.$route;
      const fullPathArr = fullPath.split('/');
      const prePath2 = fullPathArr.slice(0, fullPathArr.length - 2).join('/');
      this.match(prePath2, this.routeData);
      this.createdBreadcrumbSetting();
      for (let i = 0; i < this.breadcrumbData.length - 1; i += 1) {
        for (let j = i + 1; j < this.breadcrumbData.length; j += 1) {
          let temp;
          // 根据包含关系调整面包屑的顺序
          if (this.breadcrumbData[i].path.includes(this.breadcrumbData[j].path)) {
            temp = this.breadcrumbData[i];
            this.breadcrumbData[i] = this.breadcrumbData[j];
            this.breadcrumbData[j] = temp;
          }
        }
      }
      console.log('打印面包屑：', this.breadcrumbData);
    },
    /*
     * 菜单折叠，暂时不需要
     */
    // handleMenu() {
    //     this.isMenuHide = !this.isMenuHide;
    // },
    /**
     * 匹配面包屑数据
     * @param {String} matchPath 当前需要匹配的一级页面的路由
     * @param {Array} arr 所有页面的路由信息
     */
    match(matchPath, arr) {
      if (Array.isArray(arr)) {
        arr.forEach((item) => {
          const { path, name, query, children } = item;
          if (Array.isArray(children) && children.length) {
            this.match(matchPath, children);
          } else if (path.includes(matchPath)) {
            this.matchDataArr.push({
              path,
              label: name,
              query,
            });
          }
        });
      }
    },
    // 获取用户权限
    getUserAuth() {
      return this.getPermissionCode();
    },
    /*
     * 根据扁平数据生成树形菜单
     * @param {Array} data 所有路由的数据
     * @param {Array} menuData 树形菜单数据
     * @param {Number} type 生成数据类型（1: 不包含二级三级数据，2: 包含二级三级数据源）
     */
    createTreeMenu(type, data = this.$router.options.routes[0], menuData = []) {
      if (!Array.isArray(data.children) || !data.children.length) {
        return null;
      }
      data.children.forEach((obj) => {
        const item = obj;
        const { path, meta = {}, children, query } = item;

        let metaDisplay = meta.display;
        if (typeof metaDisplay === 'function') {
          metaDisplay = metaDisplay();
        }

        if (type === 1 && metaDisplay === 'none') {
          return '';
        }

        let menuItem = {};
        if (
          Array.isArray(children) &&
          children.length
          // && this.checkUserPermission(obj)
        ) {
          item.parentPath = data.parentPath ? `${data.parentPath}/${path}` : path;
          menuItem = {
            path,
            name: meta ? meta.title : '',
            icon: meta.menuIconName || '',
            display: metaDisplay || '',
            children: [],
            query,
          };
          menuData.push(menuItem);
          return this.createTreeMenu(type, item, menuItem.children);
        }
        menuItem = {
          path: data.parentPath ? `/${data.parentPath}/${path}` : `/${path}`,
          name: meta.title,
          icon: meta.menuIconName || '',
          query,
        };
        if (meta.roleCategory) {
          const { roleCategory } = safeParse(localStorage.getItem('userInfo'), {});
          if (roleCategory?.value === meta.roleCategory) {
            return menuData.push(menuItem);
          } else {
            return '';
          }
        }
        menuData.push(menuItem);
        return '';
      });
      return menuData;
    },
    /**
     * 判断用户是否拥有权限
     * @param {Object} obj 菜单
     */
    checkUserPermission(obj) {
      const { routePermission } = this;
      const code = _.get(obj, 'meta.code');
      return _.includes(routePermission, code) || code === 'ALL';
    },
    /*
     * 初始化页面时根据路径选中菜单项
     */
    handleMenuSelectInit() {
      const { path } = this.$route;
      let activePath = '';
      const getActivePath = (data) => {
        data.forEach((item) => {
          const { path: nowPath, children } = item;
          if (children) {
            getActivePath(children);
          } else if (path.indexOf(nowPath) > -1) {
            activePath = nowPath;
          }
        });
      };
      getActivePath(this.menuData);
      this.routerIndex = activePath;
    },
    /*
     * 菜单搜索
     */
    handleMenuSearch() {
      const { menuQuery, menuSourceData } = this;
      if (!menuQuery) {
        this.menuData = menuSourceData;
      } else {
        this.menuData = [...findTargetByDeepTraverse(menuSourceData, menuQuery)];
      }
    },
    /*
     * 路由跳转
     */
    handleSelect(path) {
      if (path !== this.$route.path) {
        this.$router.push({
          path,
        });
      }
    },
    getSide() {
      console.log('this.$route', this.$route);
      let matched = this.$route.matched[0];
      if (matched.meta && matched.meta.hideSide) {
        this.showSide = false;
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    const { userInfo, result } = await getUserInfo();
    console.log('beforeRouteEnter userInfo, result', userInfo, result);
    if (!_.isEmpty(userInfo)) {
      // 角色类型ID
      const identity = _.get(userInfo, ['identity']);
      // 翻译角色分类
      const roleCategory = translateRoleCategory(identity);
      const accountInfo = safeGet(userInfo, ['accountInfo'], {});
      const accountId = _.get(userInfo, ['accountId']);
      accountInfo.realName = result.accountName;
      accountInfo.imag = result.image || defaultToAvatar;
      const patchUserInfo = {
        ...userInfo,
        accountInfo,
        roleType: translateRoleId(identity),
        // 角色分类
        roleCategory,
        doctorInfo: {
          id: '',
          name: '',
        },
      };
      // 如果是医生类角色，具体的枚举表参照 @/utils/enums.js的定义
      if (/^(?:医生|医助|药师)$/.test(roleCategory.label)) {
        patchUserInfo.doctorInfo.id = _.get(userInfo, ['accountId']);
        patchUserInfo.doctorInfo.name = _.get(userInfo, ['accountInfo', 'realName']);
      }
      localStorage.setItem('userInfo', JSON.stringify(patchUserInfo));
      localStorage.setItem('identity', identity);
      localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
      localStorage.setItem('accountId', accountId);
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
.med-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // padding-top: 60px;
  background-color: $--background-color-light;
  .name {
    height: 60px;
    color: white;
    line-height: 60px;
    background-color: #002140;
    box-shadow: 0 2px 4px 0 #001529;
  }
  /deep/ .el-container {
    overflow: hidden;
  }
  /deep/ .el-breadcrumb__inner.is-link {
    color: $--color-primary;
    font-weight: 400;
    &:hover {
      color: $--color-primary;
      font-weight: 700;
    }
  }
}
.med-content {
  height: 100%;
  overflow: hidden;
}
.left-wrapper {
  position: relative;
  float: left;
  width: $--left-block-width;
  height: 100%;
  z-index: 99;
  transition: width ease 0.5s;
  // background-color: $--background-color-menu-light;
  background-color: #002140;
  .el-menu {
    border: 0;
  }
  /deep/ .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
  /deep/ .el-submenu__title {
    height: 40px;
    line-height: 40px;
    i {
      color: $--color-white;
      font-size: 14px;
      font-weight: 700;
    }
  }
  /deep/ .med-submenu {
    .el-menu-item,
    .el-submenu__title {
      // background-color: $--background-color-menu-base !important;
      background-color: #000c17 !important;
      &:hover,
      &.is-active {
        background-color: $--color-primary !important;
      }
    }
  }
}
/deep/ .el-breadcrumb__separator {
  margin: 0 2px 0 1px;
}
.med-menu {
  position: absolute;
  top: 60px;
  bottom: 66px;
  width: 100%;
  overflow: auto;
}
.no-back-btn {
  bottom: 0;
}
.back-btn {
  position: absolute;
  width: 100%;
  height: 66px;
  bottom: 0;
}
.back-tender {
  display: block;
  width: 224px;
  height: 40px;
  margin: 13px auto;
  font-size: $--font-size-base;
  font-weight: 400;
  color: $--color-white;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  background-color: $--background-color-menu-base;
  border-radius: 20px;
  border: 1px solid $--color-primary;
  .back-icon {
    display: inline-block;
    width: 13px;
    height: 8px;
    background-image: url('$--image-path-base/arrow-left.png');
    background-repeat: no-repeat;
    background-size: 13px 8px;
  }
}
::-webkit-scrollbar {
  //滚动条的宽度
  width: 1px;
  height: 1px;
}
.menu-search-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 9px 16px;
  height: 50px;
  box-sizing: border-box;
  background-color: $--background-color-menu-base;
  /deep/ .el-input {
    border-radius: 4px;
    overflow: hidden;
    .el-input__inner {
      padding-right: 40px;
      height: 32px;
      line-height: 32px;
      background-color: $--background-color-menu-light;
      border: 0;
      color: $--color-white;
    }
    .el-input__suffix {
      right: 0;
    }
    .el-input__icon {
      width: 40px;
      line-height: 32px;
      font-size: 16px;
      color: $--color-white;
      background-color: $--color-primary;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.right-wrapper {
  width: calc(100% - 230px);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow-y: hidden;
}
.no-menu {
  width: 100%;
}
.med-breadcrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  padding: 20px;
  margin-top: 60px;
  background-color: white;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}
.breadcrumb-desc {
  float: left;
  color: $--color-text-secondary;
}
.right-content {
  background-color: $--color-white;
  position: relative;
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}
.right-content.has-breadcrumb {
  overflow-y: auto;
}
.no-padding {
  padding: 0 !important;
  background-color: #f0f2f5;
  padding-bottom: 25px !important;
}
.noPaddingBottom {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
}
.reset-Padding {
  padding-bottom: 17px !important;
}
.btn-fix-padding {
  padding: 0 !important;
  padding-bottom: 125px !important;
  background: #eee !important;
}
.reset-no-padding {
  padding: 0 !important;
}
.btn-back {
  cursor: pointer;
}
.contents {
  height: 60px;
}
.content_box {
  height: calc(100% - 60px);
  padding: 30px 20px 80px 20px;
  background: #f0f2f5;
}
/deep/ .med-submenu {
  text-indent: 2em;
}
/deep/ .el-timeline {
  margin: 0;
  font-size: 14px;
  list-style: none;
  padding-left: 14px;
}
</style>
